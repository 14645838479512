import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/scss/index.scss'
// common
import Dialogue from "@/components/common/dialogue.vue"

export default {
    props: ['articleBasicInfo'],
    components: {
        DatePicker,
        Dialogue
    },
    data: function() {
        return {
            activeChangeStateDialogue: '',
            targetAction: '',
            isPublishNow: '0',
            newPublishTime: this.articleBasicInfo.publishTime,
            oldPublishTime: this.articleBasicInfo.publishTime,

            isPostingApi: {
                updateArticle: false,  // 更新文章
                auditArticle: false  // 送審文章
            }
        }
    },
    computed: {
        publishOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '排程'
                },
                {
                    value: '1',
                    text: '立即'
                }
            ]
            return opts;
        },
        updateArticleData: {
            get() {
                return this.$store.state.adminArticle.updateArticleData;
            },
            set(value) {
                this.$store.commit('adminArticle/setUpdateArticleData', value);
            }
        },
        isInputFormatError() {
            return this.$store.state.adminArticle.isInputFormatError;
        },
        isNotAllowToUpdateData() {
            return this.$_.isEmpty(this.$_.omit(this.updateArticleData, ['saveType'])) ||
                    this.isInputFormatError;
        },
        isNotAllowToChangeState() {
            return !this.$_.isEmpty(this.$_.omit(this.updateArticleData, ['saveType'])) ||
                    this.isInputFormatError;
        },
    },
    methods: {
        updateArticle() {
            if (!this.$route.params.id ||
                (this.updateArticleData && this.updateArticleData.saveType === undefined) ||
                this.isNotAllowToUpdateData ||
                this.isPostingApi.updateArticle) {
                return;
            }

            // 依據儲存類型, 指定不同 api url 與 params
            let apiUrl = '', params = null;
            let currentSaveType = this.updateArticleData.saveType;

            this.updateArticleData = this.$_.omit(this.updateArticleData, ['saveType']);

            switch (currentSaveType) {
                // 課程資料
                case 'info':
                    this.updateArticleData.id = this.$route.params.id;
                    apiUrl = '/admin_api/article/update_article_info';
                    params = this.getUpdateInfoParams();
                    break;
                // 英文補給
                case 'content':
                    this.updateArticleData.updateInfo.articleId = this.$route.params.id;
                    apiUrl = '/admin_api/article/update_paragraph_contents_materials';
                    params = this.getContentParams();
                    break;
                // 內容編輯(markdown)
                case 'mkContent':
                    apiUrl = '/admin_api/article/update_nonverbal';
                    params = this.getMkContentParams();
                    break;
                // 內容補充(markdown)
                case 'mkSupplement':
                    apiUrl = '/admin_api/article/save_article_supplement';
                    params = this.getMkSupplementParams();
                    break;
                // 有聲文本
                case 'textToVoice':
                    apiUrl = '/admin_api/article/update_nonverbal_sentences';
                    params = this.getTextToVoiceParams();
                    break;
                // 練習題
                case 'practice':
                    this.updateArticleData.updateInfo.articleId = this.$route.params.id;
                    apiUrl = '/admin_api/practice/update_practice';
                    params = this.getPracticeParams();
                    break;
                // 負責編輯
                case 'editors':
                    this.updateArticleData.articleId = this.$route.params.id;
                    apiUrl = '/admin_api/article/update_article_editors';
                    params = this.getRawDataParams();
                    break;
            }


            if (!apiUrl || !params) {
                return;
            }

            this.isPostingApi.updateArticle = true;

            this.$httpRequest.post(apiUrl, params)
                .then(response => {
                    this.isPostingApi.updateArticle = false;

                    if (response.data.state == 'OK') {
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                        // 更新儲存"負責編輯"頁面資料後, 需判斷是否刪除自己以做對應處理
                        if (currentSaveType == 'editors') {
                            let result = response.data.result;
                            if (result) {
                                this.$store.commit('adminArticle/setIsSelfDeletedFromEditors', result.is_self_deleted);
                            }
                        }
                        // 觸發更新成功, 以讓右邊頁面接收後做對應處理
                        this.$store.commit('adminArticle/setIsFinishUpdatingData', true);
                    }
                })
                .catch(function (error) {
                    console.log('catched error: ' + error);
                });
        },
        // form data
        getUpdateInfoParams() {  // 文章資料
            let params = new FormData();

            let excludeKeys = ['cover', 'deleteCoverFile'];  // 排除不需要的 keys
            let updateData = this.$_.omit(this.updateArticleData, excludeKeys);

            updateData.deleteFiles = [];
            if ('deleteCoverFile' in this.updateArticleData && this.updateArticleData.deleteCoverFile) {
                updateData.deleteFiles.push('cover');
            }
            if (!updateData.deleteFiles.length) {  // 如果沒有要刪除的檔案, 則不需帶 deleteFiles 這個 key
                updateData = this.$_.omit(updateData, ['deleteFiles']);
            }

            params.append('articleInfo', JSON.stringify(updateData));

            // 檔案類型
            // 基本資料 - 課程封面
            if ('cover' in this.updateArticleData && this.updateArticleData.cover.file) {
                params.append('cover', this.updateArticleData.cover.file);
            }

            return params;
        },
        // form data
        getContentParams() {
            let params = new FormData(),
                paragraphList = this.updateArticleData.paragraphList ? this.updateArticleData.paragraphList : '',
                oldParagraphList = this.updateArticleData.oldParagraphList ? this.updateArticleData.oldParagraphList : '';

            let excludeKeys = ['paragraphList', 'wordBlockList'];  // 排除不需要的 keys
            let updateData = this.$_.omit(this.updateArticleData, excludeKeys);
            params.append('updateInfo', JSON.stringify(updateData.updateInfo));

            if (paragraphList && oldParagraphList) {
            // 把[段落內容]的圖片，一張一張append
                oldParagraphList.forEach((el, index) => {
                    el.contents.forEach((sub, subIndex) => {
                        if (sub.type == 'sentence') { // 詞句
                            if (paragraphList[index].contents[subIndex].cover.path != sub.cover.path) {
                                params.append('apc_' + sub.apc_id + '_0', paragraphList[index].contents[subIndex].cover.file);
                            }
                        }
                        if (sub.type == 'image') { // 圖片區塊
                            if (paragraphList[index].contents[subIndex].cover.path != sub.cover.path) {
                                params.append(sub.apc_id, paragraphList[index].contents[subIndex].cover.file);
                            }
                        }
                    });
                });
            }

            return params;
        },
        getMkContentParams() {
            let params = {
                updateInfo: {
                    articleId: this.$route.params.id,
                    content: this.updateArticleData.content
                }
            };

            return params;
        },
        getMkSupplementParams() {
            let params = {
                articleId: this.$route.params.id,
                supplement: this.updateArticleData.content
            };

            return params;
        },
        getTextToVoiceParams() {
            let params = new FormData(),
                updateInfo =  {
                    articleId: this.$route.params.id,
                    sentences: this.updateArticleData.content
                };
            params.append('updateInfo', JSON.stringify(updateInfo));

            return params;
        },
        // form data
        getPracticeParams() {  // 練習題資料
            let params = new FormData(),
                updateData = this.updateArticleData;

            // 檔案上傳專用
            if (updateData.updateInfo.question && updateData.updateInfo.question.update) {
                updateData.updateInfo.question.update.forEach((el) => {
                    if (el.imageFile) { // 如果有題目圖片
                        params.append(el.image, el.imageFile);
                        delete el.imageFile;
                    }
                    if (el.imageFiles) {
                        el.imageFiles.forEach((file, index) => {
                            params.append(el.images[index], file);
                        })
                    }
                    if (el.audioFile) { // 如果有附加音檔
                        params.append(el.upload_audio, el.audioFile);
                        delete el.audioFile;
                    }
                    if (el.mediaFile) { // 如果有附加音檔
                        params.append(el.comment_media, el.mediaFile);
                        delete el.mediaFile;
                    }

                    if (el.options) {
                        el.options.forEach((option) => {
                            if (option.imageFile) { // 如果有選項圖片
                                params.append(option.image, option.imageFile);
                                delete option.imageFile;
                            }
                        });
                    }
                });
            }

            // 題組新增子題檔案
            if (updateData.updateInfo.groups) {
                updateData.updateInfo.groups.forEach((el) => {
                    el.subQuestions.forEach((sl, sIndex) => {
                        if (sl.imageFile) { // 如果有題目圖片
                            // console.log('group-' + el.id + '-question-' + sIndex + '-image');
                            params.append('group-' + el.id + '-question-' + sIndex + '-image', sl.imageFile);
                            delete sl.imageFile;
                        }
                        if (sl.mediaFile) { // 如果有解析媒體
                            // console.log('group-' + el.id + '-question-' + sIndex + '-comment-file');
                            params.append('group-' + el.id + '-question-' + sIndex + '-comment-file', sl.mediaFile);
                            delete sl.mediaFile;
                        }
                        sl.options.forEach((option, oIndex) => {
                            if (option.imageFile) { // 如果有選項圖片
                                // console.log('group-' + el.id + '-question-' + sIndex + '-option-' + oIndex + '-image');
                                params.append('group-' + el.id + '-question-' + sIndex + '-option-' + oIndex + '-image', option.imageFile);
                                delete option.imageFile;
                            }
                        });
                    })
                });
            }

            // 檔案上傳專用
            if (updateData.updateInfo.conversations && updateData.updateInfo.conversations.update) {
                updateData.updateInfo.conversations.update.forEach(el => {
                    el.subQuestions.forEach(sl => {
                        if (sl.audioFile) { // 如果有附加音檔
                            params.append(sl.upload_audio, sl.audioFile);
                            delete sl.audioFile;
                        }
                    });
                });
            }

            this.$store.commit('adminArticle/setSubjectIndex', '');

            params.append('updateInfo', JSON.stringify(updateData.updateInfo));

            return params;
        },
        getRawDataParams() {
            let params = null;
            params = this.updateArticleData;
            return params;
        },
        disableDateFromNow(date) {
            // disable小於今天以前的日期
            return date < new Date().setHours(0, 0, 0, 0);
        },
        disableTimeFromNow(date) {
            // disable小於目前時間(時)以前的時間
            return date <= new Date();
        },

        checkArticleEditedItem(nextState) {
            if (!this.$route.params.id ||
                this.isNotAllowToChangeState ||
                this.isPostingApi.checkEditedItem ||
                !nextState) {
                return;
            }

            let params = {
                articleId: this.$route.params.id
            }

            this.isPostingApi.checkEditedItem = true;

            this.$httpRequest.get('/admin_api/article/check_article_completeness', params)
                .then(response => {
                    this.isPostingApi.checkEditedItem = false;

                    if (response.data.state == 'OK') {
                        this.showChangeStateDialogue(nextState);
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        showChangeStateDialogue(newAction) {
            if (this.isNotAllowToChangeState || !newAction) {
                return;
            }

            this.activeChangeStateDialogue = '';
            this.targetAction = newAction;
            this.isPublishNow = '0';
            this.newPublishTime = this.articleBasicInfo.publishTime;

            switch (this.targetAction) {
                // { 任何狀態 } -> 編輯中
                case 'edit':
                    this.activeChangeStateDialogue = 'changeToEditingStatePromptDialogue';
                    break;
                // 編輯中 -> 待審核
                case 'audit':
                    this.activeChangeStateDialogue = 'auditArticlePromptDialogue';
                    break;
                // 待審核 -> { 已排程 | 上架中 }
                case 'schedule':
                    this.activeChangeStateDialogue = 'publishArticleSettingsDialogue';
                    break;
            }

            if (!this.activeChangeStateDialogue) {
                return;
            }

            $(`#${this.activeChangeStateDialogue}`).modal('show');
        },
        handleChangingArticleState() {
            if (this.$parent.isPostingApi.changeState) {
                return;
            }

            let options = {
                activeDialogue: this.activeChangeStateDialogue,
                targetAction: this.targetAction,
                isPublishNow: this.isPublishNow,
                publishTime: this.$util.datetimeToUnixTimestamp(this.newPublishTime, 0)
            }
            console.log(options)

            this.$parent.changeArticleState(options);
        }
    }
}
